

.flip-book {
  margin:  0 auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  display: none;
  background-size: cover;
}

.page {

  font-family: 'GFS Didot', serif;

  margin: 0 auto;
  height: 100vh;
  padding: 0;
  background-color: hsl(35, 55, 98);
  color: #10100e;
  border: solid 1px hsl(0, 0%, 100%);
  overflow: hidden;

  .page-content {
    font-family: 'GFS Didot', serif;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    .page-header {
      height: 30px;
      font-size: 100%;
      text-transform: uppercase;
      text-align: center;
    }

    .page-image {
      height: 100%;
    }

    .page-text {
      height: 100%;
      flex-grow: 1;
      font-size: 80%;
      text-align: justify;
      margin-top: 0px;
      padding-top: 0px;
      box-sizing: border-box;
      border-top: solid 1px hsl(0, 0%, 95%);
    }

    .page-footer {
      height: 30px;
      border-top: solid 1px hsl(0, 0%, 96%);
      font-size: 80%;
      color:#C4A011;
    }
  }

  &.--left { // for left page (property will be added automatically)
    border-right: 0;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
  }

  &.--right { // for right page (property will be added automatically)
    border-left: 0;
    box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);

    .page-footer {
      text-align: right;
    }
  }

  &.hard { // for hard page
    background-color: #FCCE16;
    border: solid 1px#C4A011;
  }

  &.page-cover {
    background-image: url('https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/public/heartbeat_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500');
    color:  #705B0A;
    border: solid 1px#C4A011;

    h2 {
      text-align: center;
      padding-top: 50%;
      font-size: 210%;
    }

    &.page-cover-top {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    }

    &.page-cover-bottom {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
    }
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }
  .video-land{
    width: 45px;
    height: 200px;
  }
  .video-port {
    width:40px;
    height: 00px;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
